.homepage4_thumb_carousel{
    padding: 0% 0%;
}
.carousel .thumbs-wrapper{

    margin:0px;
    display: flex;
    justify-content: center;
}
.carousel .thumb.selected, .carousel .thumb:hover{
    border: none;
}
.carousel .thumbs{

    display: flex;
    justify-content: center;
    padding: 0px;
    margin: 0px;
    width:100%;
    /* transform: none !important; */
}
.carousel .thumb,
.carousel .thumb:focus
{ 
    border:none;
    padding: 5px;
    margin: 0px;
    /* width: 25% !important; */
}
.carousel .control-arrow:before{
    margin: 0px !important;
}
.carousel .control-prev.control-arrow{
    left:-20px;
}
.carousel .control-next.control-arrow{  
    right: -20px;
} 
.carousel .control-prev.control-arrow:before {
    border-right: 8px solid #000;
}
.carousel .control-next.control-arrow:before {
    border-left: 8px solid black;
}
@media(min-width: 576px){
    
    .homepage4_thumb_carousel{
        padding: 0% 8%;
    }   
}
@media(min-width: 768px){
    
    .homepage4_thumb_carousel{
        padding: 0% 10%;
    }   
    .carousel .control-prev.control-arrow{
        left:-22px;
    }
    .carousel .control-next.control-arrow{  
        right: -22px;
    } 
    .carousel .control-prev.control-arrow:before {
        border-right: 11px solid #000;
    }
    .carousel .control-next.control-arrow:before {
        border-left: 11px solid black;
    }
}
@media(max-width:575px){
    
    .thumb{

        /* width: 500px !important; */
    }
    .thumb img{

        width: 100%;
    }
}