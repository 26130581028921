.Articles .bannerimage{

    width: 100%;
    height: auto;
    min-height: 30vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
@media(max-width:576px){
    
    .Articles .bannerimage{

        min-height: 35vh;
    }
}
.Articles .heading_text{

    left:50%; 
    top:50%; 
    transform: translate(-50%, -50%);
    width:100%;
}

.Articles .image_content{

    position: relative;
    /* background: #000; */
    opacity: 1 !important;
    backdrop-filter: blur(4px);
    margin-top: -2px;
}
.Articles .image_content{
        
    opacity: 0.8;
    margin-top: 0px;
}   
.Articles .image_content{

    /* position: absolute; */
    /* border-radius: 0px 55px 0px 0px ; */
    /* bottom:1px; */
}
@media(max-width:767px){
    .Articles .image_content{

        border-radius: 0px 0px 0px 0px ;
    }   
}
@media(min-width:768px){
    .Articles .image_content{
        
        opacity: 0.8;
        margin-top: 0px;
    }   
}
.Articles .image_content .heading{

    min-height:50px;
}
@media(min-width:768px){
     
    .Articles .image_content{

        /* position: absolute; */
        /* border-radius: 0px 55px 0px 0px ; */
    }
}
@media(min-width:1200px){

    .Articles .image_content .heading{

        min-height:54px;
    }
}
@media(min-width:1500px){

    .Articles .image_content .heading{

        min-height: 60px;
    }
}
@media(min-width:1600px){

    .Articles .image_content .heading{

        min-height:72px;
    }
}

@media(min-width:768px){  
    .article_image,
    .article_image2,.article_image3{

        height: 370px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    } 
    .article_below_576px{
        display: none;
    }
}

@media(min-width:1600px){  .article_image,.article_image2,.article_image3{ height: 420px; } }

@media(min-width:1700px){  .article_image,.article_image2,.article_image3{ height: 450px; } }

@media(min-width:1900px){  .article_image,.article_image2,.article_image3{ height: 500px; } }

.article_image2,
.article_image3{
        width: 100%;
}
@media(min-width:992px){
    .article_image2,
    .article_image3{
        width: 48%;
    }
    .article_image2{
        margin-right: 2%;
    }
    .article_image3{
        margin-left: 2%;
    }
}
/********** Article List BannerImage ***************/
.article_image{
    width:100%;
}
@media(min-width:768px){
    
    .article_image:nth-child(3n-1),
    .article_image:nth-child(3n){
        width:48%;
    }
    .article_image:nth-child(3n-1){
        margin-right: 2%;
    }
    .article_image:nth-child(3n){
        margin-left:2%;
    }
}

.text-wrapp{
    overflow-wrap: break-word;
}
.text-wrapp1{

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 40px;
}
@media(max-width:575px){
    .text-wrapp1{

        height: auto;
        max-height: 40px;
    }
}
@media(min-width:768px){
    .text-wrapp1{

        height: 43px;
    }   
}
@media(min-width:1500px){
    .text-wrapp1{

        height: 50px;
    }   
}
@media(min-width:1600px){
    .text-wrapp1{

        height: 61px;
    }   
}
@media(min-width:480px){
    .article_imagess > p img {
        max-width: 350px !important;
        height: auto !important;
    }
}

@media(min-width:992px){
    .article_imagess > p img {
        max-width: 500px !important;
        margin: auto;
        display: flex;
    }
}

.main_article_list_images{
    width:100%;
}

@media(min-width:576px){

    .main_article_list_images{
        width: 350px;
    }
}
@media(min-width:992px){
    
    .main_article_list_images{
        width:500px;
    }
}
/* .article_imagesses > p img {
    display: flex;
    margin: 0 auto;
    max-width: 100%;
} */
.for_height_list{
    height: auto;
}
@media(min-width:576px){
    .for_height_list{
        min-height: 63px;
    }   
}
@media(min-width:768px){
    .for_height_list{
        min-height: 50px;
    }   
}
@media(min-width:1200px){
    .for_height_list{
        min-height: 72px;
    }   
}
@media(min-width:1500px){
    .for_height_list{
        min-height: 81px;
    }   
}
@media(min-width:1600px){
    .for_height_list{
        min-height: 100px;
    }   
}
@media(max-width:480px){
    .article_imagess > p img {
        max-width: 100% !important;
        height: auto !important;
    }
}