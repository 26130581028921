@import url('https://fonts.googleapis.com/css2?family=Times+New+Roman:wght@200;300;400;500;600;700;800&display=swap');

.App{
  font-family: 'Times New Roman';
}

.cursor-pointer{
  cursor: pointer;
}

@media(max-width:575px){  
  .p_text{
    font-size: 14px;
  }
  .container{
    width:95% !important;
  }
}

.p-navbar{
  padding-top:70px;
}
@media(min-width:992px){  
  .p-navbar{
      padding-top: 76px;
  }
}
@media(min-width:1600px){

  .p-navbar{

    padding-top: 104px;
  }
}

/*--------- custom scrollbar ----------*/

@media(min-width:576px){

  ::-webkit-scrollbar {
      width: 10px;
  }
  ::-webkit-scrollbar-track {
  
      border-radius: 0px;
  }
  ::-webkit-scrollbar-thumb {
      background:	#B0B0B0;  
      border-radius: 0px ;
  }
  ::-webkit-scrollbar-thumb:hover {
      background:#A0A0A0 ; 
  }
}
@media(min-width:768px){

  ::-webkit-scrollbar {
      width: 12px;
  }
}
@media(min-width:992px){

  ::-webkit-scrollbar {
      width: 13px;
  }
}
@media(min-width:1400px){

  ::-webkit-scrollbar {
      width: 17px;
  }
}



/****** Font style and weight ,color *********/

.color-primary{ color: #18191F; }
.color-secondary{ color: #737373; }
.c-4e4e4e{ color: #4E4E4E;}

.fw-300{ font-weight:300 !important}
.fw-400{ font-weight:400 !important}
.fw-500{ font-weight:500 !important}
.fw-600{ font-weight:600 !important}
.fw-700{ font-weight:700 !important}


.fs-14{ font-size:13px; }
.fs-16{ font-size:14px; }
.fs-18{ font-size:16px; }
.fs-24{ font-size:18px; }
.fs-30{ font-size:24px; }
.fs-40{ font-size:31px; }
.fs-42{ font-size:33px; }
.fs-48{ font-size:35px; }
.fs-72{ font-size:50px; }

@media(min-width:768px){
  
  .fs-14{ font-size:13px; }
  .fs-16{ font-size:15px; }
  .fs-18{ font-size:17px; }
  .fs-24{ font-size:20px; }
  .fs-30{ font-size:27px; }
  .fs-40{ font-size:35px; }
  .fs-42{ font-size:38px; }
  .fs-48{ font-size:42px; }
  .fs-72{ font-size:60px; }
}
@media(min-width:1200px){
  
  .fs-14{ font-size:14px; }
  .fs-16{ font-size:16px; }
  .fs-18{ font-size:18px; }
  .fs-24{ font-size:24px; }
  .fs-30{ font-size:30px; }
  .fs-40{ font-size:40px; }
  .fs-42{ font-size:42px; }
  .fs-48{ font-size:48px; }
  .fs-72{ font-size:72px; }
}
@media(min-width:1500px){
  
  .fs-14{ font-size:16px; }
  .fs-16{ font-size:18px; }
  .fs-18{ font-size:20px; }
  .fs-24{ font-size:26px; }
  .fs-30{ font-size:33px; }
  .fs-40{ font-size:43px; }
  .fs-42{ font-size:45px; }
  .fs-48{ font-size:55px; }
  .fs-72{ font-size:75px; }
}
@media(min-width:1600px){

  .fs-14{ font-size:20px !important; }
  .fs-16{ font-size:22px !important; }
  .fs-18{ font-size:24px !important; }
  .fs-24{ font-size:30px !important; }
  .fs-30{ font-size:39px !important; }
  .fs-40{ font-size:48px !important; }
  .fs-42{ font-size:51px !important; }
  .fs-48{ font-size:60px !important; }
  .fs-72{ font-size:80px !important; }
}

/******* Hover Button **********/
.hover{
  background-color: #fff !important;
  color: #000 !important;
  border: 2px solid transparent !important;
  box-shadow: none !important;
}
.hover:hover{

  background-color: #000 !important;
  color: #fff !important;
  border: 2px solid #fff !important;
  box-shadow: none !important;
}
.hover2{
  
  background-color: #000 !important;
  color: #fff !important;
  border: 2px solid transparent !important;
  box-shadow: none !important;
}
.hover2:hover{  
  background-color: #fff !important;
  color: #000 !important;
  border: 2px solid #000 !important;
  box-shadow: none !important;
}

/********* Container-fluid ************/
@media(min-width:1100px){
  
  .container{

    max-width:960px !important;
  }
}
@media(min-width:1200px){
  
  .container{

    max-width:1050px !important;
  }
}
@media(min-width:1300px){
  
  .container{

    max-width:1100px !important;
  }
}
@media(min-width:1400px){
  
  .container{

    max-width:1130px !important;
  }
}
@media(min-width:1500px){
  
  .container{

    max-width:1170px !important;
  }
}
@media(min-width:1600px){
  
  .container{

    max-width:1320px !important;
  }
}
@media(min-width:1800px){
  
  .container{

    max-width:1400px !important;
  }
}
@media(min-width:1900px){
  
  .container{

    max-width:1500px !important;
  }
}


.pt-6 {padding-top: 6%; }
.pt-10 {padding-top: 10%; }

.onhover:hover{
  color:black;
  font-weight: 600;
  font-style: bold;
}
.form-control:disabled{
  background-color: #fff !important;
}
input:-webkit-autofill {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 50px white inset;
}


/***** For carousel ***********/

.imagge{
  min-height: 300px;
  max-height: 300px;
}
.imagge img{
  min-height: 300px;
  max-height: 300px;
  object-fit: contain !important;
}
li.thumb{
  min-height: 80px !important;
  max-height: 80px !important;
  min-width: 80px !important;
  max-width: 80px !important;
}
li.thumb img{
    object-fit: cover !important;
} 
.carousel .thumbs{
  justify-content: flex-start !important; 
}
.slides-center .carousel .thumbs{
  width: auto !important;
}
.carousel-status{
  display: none;
}
/* @media(min-width:1500px){
  li.thumb{
    min-height: 150px !important;
    max-height: 150px !important;
  }
  li.thumb img{
    min-height: 150px !important;
    max-height: 150px !important;
    object-fit: cover !important;
  } 
} */
@media(min-width:1200px){
  
  .imagge{
    min-height: 350px;
    max-height: 350px;
  }
  .imagge img{
    min-height: 350px;
    max-height: 350px;
  }
}
@media(min-width:1500px){
  
  .imagge{
    min-height: 500px;
    max-height: 500px;
  }
  .imagge img{
    min-height: 500px;
    max-height: 500px;
  }
}

.color18{
  color: #18191F !important;
}

.height-text-wrapp{

  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden !important; 
}

.shadow_hover:hover{
  background-color: #F9F9F9 !important;
  /* background-color: red !important; */
}
.button_pd24{

  padding-top: 4px !important;
  padding-bottom: 4px !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  border-radius: 6px !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
/* 
ul.thumbs.animated {
  transform:none !important;
  transition-duration: 1ms !important;
}

ul.thumbs {
  transform: none !important;
}
.control-arrow{
  display: none !important;
} */
.color-18191F,
.color-18191F:hover{
  color: #18191F;
}