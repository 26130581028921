@media(min-width:1600px){
    .social_icons{
        width: 36px;
    }
}
.icon2{
    width: 28px;
}
.icon2:hover {
    background: #262626;
    border-radius: 50%;
}
.icon2:hover path{

    fill: #fff;
}
@media(min-width:992px){
    .icon2{
        width: 30px;
    }   
}
@media(min-width: 1400px){
    .icon2{
        width: 35px;
    }   
}
@media(min-width:1600px){
    .icon2{
        width: 42px;
    }   
}
.footer_icon2{

    width: 22px;
}
.footer_icon2:hover{
    background: #262626 !important;
    border-radius: 50%;
}
.footer_icon2:hover path{

    fill: #fff;
}
.email2{
    width:22px
}
.email2:hover{
    background: #262626;
    border-radius: 50%;
}
.email2:hover path{
    stroke: #fff;
}
@media(min-width:1200px){
    
    .footer_icon2{

        width: 26px;
    }
    .email2{
        width: 26px
    }
}
@media(min-width:1500px){
    
    .footer_icon2{

        width: 30px;
    }
    .email2{
        width: 30px
    }
}


/*************** Website Review change ****************/
.heading_footer_logo{
    padding-bottom: 25px;
}
.for_padding{
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 15px;
}
.for_padding2{
    margin-left: 5px;
    margin-right: 5px;
    /* margin-bottom: 15px; */
}
.hr_class{
    margin-top: 10px;
    margin-bottom: 24px;
}
.brand_logo2{
    width: auto;
}
@media(min-width:992px){
    
    .heading_footer_logo{
        padding-bottom: 35px;
    }
    .for_padding{
        padding-left: 25px;
        padding-right: 25px;
        padding-bottom: 21px;
    }
    .for_padding2{
        margin-left: 12.5px;
        margin-right: 12.5px;
    }
    .hr_class{
        margin-top: 32px;
        margin-bottom: 24px;
    }
    .brand_logo2{
        width: auto;
    }
}