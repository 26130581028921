.Blog .bannerimage{

    width: 100%;
    height: auto;
    min-height: 30vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
@media(max-width:576px){
    
    .Blog .bannerimage{

        min-height: 35vh;
    }
}
@media(max-width:767px){
    .wathesss{

        display: flex;
        flex-direction: column;
    }
    .wathesss .image_content{
        opacity: 1 !important;
    }
}
.Blog .image_content{

    position: relative;
    /* background: #000; */
    opacity: 1;
    backdrop-filter: blur(4px);
    margin-top: -2px;
}
@media(min-width:768px){
.Blog .image_content{
        
    /* opacity: 0.8; */
    margin-top: 0px;
}   
}
.Blog .image_content{

    position: relative;
    border-radius: 0px 0px 0px 0px ;
    bottom:1px;
}
@media(min-width:768px){
    .Blog .image_content{
        
        /* opacity: 0.8; */
        margin-top: 0px;
    }   
}
.Blog .image_content .heading{

    min-height:50px;
}
@media(min-width:768px){
     
    .Blog .image_content{

        /* position: absolute; */
        /* border-radius: 0px 55px 0px 0px ; */
    }
}
@media(min-width:1200px){

    .Blog .image_content .heading{

        min-height:54px;
    }
}
@media(min-width:1500px){

    .Blog .image_content .heading{

        min-height: 60px;
    }
}
@media(min-width:1600px){

    .Blog .image_content .heading{

        min-height:72px;
    }
}
/* .categoriess p:last-child span{
    display: none;
} */
.category_heading{
    font-size: 16px;
}
.category_fields{
    padding: 5px 10px;
    font-size: 14px;
}