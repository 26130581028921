.commonfooter .image_content{

    position: relative;
    background: #000;
    opacity: 1;
    backdrop-filter: blur(4px);
    margin-top:-2px;
}
@media(min-width:768px){
    
    .commonfooter .image_content{

        opacity: 0.8;
        margin-top: 0px;
    }
}
.commonfooter .image_content .heading{

    min-height:50px;
}
@media(min-width:576px) and (max-width:991px){

    .p-text-height{
        min-height:42px;
    }
}
@media(min-width:992px){

    .p-text-height{
        min-height:48px;
    }
}
@media(min-width: 1500px){

    .p-text-height{
        min-height:55px;
    }
}
@media(min-width: 1600px){

    .p-text-height{
        min-height: 66px;
    }
}
@media(min-width:768px){
     
    .commonfooter .image_content{

        position: absolute;
        border-radius: 0px 55px 0px 0px ;
    }
}

.explore_button{
    background: #18191F;
    color: #fff;
    border: 2px solid #18191F;
    border-radius: 6px !important;
}
.explore_button:focus{
    box-shadow: none !important;
}
.explore_button:hover{

    background-color: #fff;
    border: 2px solid #18191F;
}