/* .homepage3 .image1{

    width:61.5%;
    margin-right:2.5%;
}
.homepage3 .image2{

    width:36%;
}
@media(max-width:991px){
    
    .homepage3 .image1{

        width:100%;
        margin-right:0%;
    }
    .homepage3 .image2,
    .homepage3 .image2 img{
    
        width: 100%;
    }
} */
@media(max-width:480px){
    
    .homepage3 .lowerimage_content{

        left:50%; 
        top:50%; 
        transform: translate(-50%, -50%);
        width:100%;
        padding: 0 2%;
        padding-top: 40px;
        z-index: 2;
    }
}
@media(min-width:481px){
    
    .homepage3 .lowerimage_content{

        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        bottom: 40px;
        width:100%;
        padding: 0 2%;
    }
}


/******************* Optional ***************************************/
.imagee1,.imagee2{
    
    height: 250px;
    background-size: cover;
    background-position: center;
    border:none;
}
/* @media(min-width:576px){
    .imagee1,.imagee2{
    
        height: 250px;
    }   
}
@media(min-width:768px){
    .imagee1,.imagee2{
    
        height: 250px;
    }   
}
@media(min-width:1600px){
 
    .imagee1,.imagee2{
    
        height: 420px;
    }
}
@media(min-width:1700px){
 
    .imagee1,.imagee2{
    
        height: 470px;
    }
}
@media(min-width:1900px){
 
    .imagee1,.imagee2{
    
        height: 530px;
    }
} */
@media(min-width:992px){
    .over-992-center{
        height: 75px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
@media(min-width:1600px){
    .over-992-center{
        height: 95px;
    }
}
.overlay-landingpage {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    z-index: 1;
}
.page3_z-index2{
    z-index: 2;
}