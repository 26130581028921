.header {

    width: 100%;
    position: fixed;
    z-index: 10;
    background-color: white;
    box-shadow: 2px 2px 10px grey;
}

.navbar {
    padding: 15px 0px;
}

.nav-item {

    /* padding-left: 7px; */
}

.nav-item .nav-link {

    color: #18191F !important;
}

.nav-link.active_class {

    border: 1px solid transparent;
}

@media(max-width:575px) {

    .navbar-light .navbar-brand {
        width: 75%;
    }

    .brand_logo {
        width: 100%;
    }
}

@media(min-width:480px) {

    .nav-item {

        /* padding-left: 15px; */
    }
}

@media(min-width:992px) {

    .navbar-expand-lg .navbar-collapse {

        width: 100%;
        padding: 10px 0px;
    }

    .navbar {

        padding: 10px 0px;
    }

    .nav-item {

        padding-left: 0px;
        /* margin: 0px 3px; */
    }

    .nav-link.active {

        border-bottom: 1px solid #18191F !important;
        font-weight: 600;
    }

    .brand_logo {

        width: 300px;
    }
}

.navbar-brand {
    padding-top: 0px !important;
    margin-right: 0px !important;
}

.nav-link.active {

    border-bottom: 1px solid #18191F !important;
    font-weight: 600;
}

@media(min-width:1100px) {

    .nav-item {

        /* margin: 0px 15px; */
    }
}

@media(min-width:1200px) {

    .nav-item {

        /* margin: 0px 20px; */
    }
}

@media(min-width:1300px) {

    .nav-item {

        /* margin: 0px 25px; */
    }

    .brand_logo {

        width: 380px;
    }
}

@media(min-width:1400px) {

    .nav-item {

        /* margin: 0px 30px; */
    }
}

@media(min-width:1500px) {

    .nav-item {

        /* margin: 0px 35px; */
    }
}

@media(min-width:1600px) {

    .nav-item {

        /* margin: 0px 25px; */
    }

    .navbar {

        padding: 20px 0px;
    }

    .brand_logo {

        width: 380px;
    }
}

/***** Dropdown Menu *******/
.dropdown_menu {
    position: relative;
    padding-left: 20px;
}

.dropdown_menu a:hover {

    color: black;
}

@media(min-width:992px) {

    .dropdown_menu {

        position: fixed;
        text-align: center;
        top: 65px;
        margin-left: -65px;
        background-color: white;
        padding: 17px;
        border-radius: 5px 5px 5px 5px;
        box-shadow: 10px 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    }
}

@media(min-width:1600px) {

    .dropdown_menu {

        margin-left: -100px;
        padding: 30px;
        top: 106px;
    }
}

/***** Dropdown image *******/
#dropdown_image {

    width: 11px;
    right: 0px;
    margin-top: 3px;
    color: #000;
}

#dropdown_image.active {

    transform: rotate(180deg);
}

@media(min-width:1600px) {

    #dropdown_image {

        margin-top: 7px;
    }
}

/* For Hover Purpose */
.nav-link:hover {

    color: #0b0c0e !important;
    font-weight: 600;
}

.nav-link:hover #dropdown_image {

    color: #0b0c0e;
}

.dropdown_menu a::selection {

    background-color: transparent;
}

@media(max-width:991px) {

    .container23 {
        max-width: 100% !important;
        width: 100% !important;
    }
}


/************ New CSS for alignment *********************/
@media(min-width:992px) {
    .right.collapse.navbar-collapse {
        display: flex;
        justify-content: flex-end;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-left: 0px;
        padding-right: 0px;
    }

    .homeee {
        padding-right: 20px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .collectionnn {
        padding-right: 20px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .watch-parts {
        padding-right: 20px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .articlee {
        padding-left: 20px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .blogg {
        padding-left: 20px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .watchh {
        padding-left: 20px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .contactt {
        padding-left: 20px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }
}

@media(min-width:1100px) {

    .homeee {
        padding-right: 25px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .collectionnn {
        padding-right: 25px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .articlee {
        padding-left: 25px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .blogg {
        padding-left: 25px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .watchh {
        padding-left: 25px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .contactt {
        padding-left: 25px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }
}

@media(min-width:1200px) {

    .homeee {
        padding-right: 35px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .collectionnn {
        padding-right: 35px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .articlee {
        padding-left: 35px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .blogg {
        padding-left: 35px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .watchh {
        padding-left: 35px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .contactt {
        padding-left: 35px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }
}

@media(min-width:1300px) {

    .homeee {
        padding-right: 32px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .collectionnn {
        padding-right: 32px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .articlee {
        padding-left: 32px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .blogg {
        padding-left: 32px;
    }

    .watchh {
        padding-left: 32px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .contactt {
        padding-left: 32px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }
}

@media(min-width:1400px) {

    .homeee {
        padding-right: 35px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .collectionnn {
        padding-right: 35px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .articlee {
        padding-left: 35px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .blogg {
        padding-left: 35px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .watchh {
        padding-left: 35px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .contactt {
        padding-left: 35px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }
}

@media(min-width:1500px) {

    .homeee {
        padding-right: 32px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .collectionnn {
        padding-right: 32px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .articlee {
        padding-left: 32px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .blogg {
        padding-left: 32px;
    }

    .watchh {
        padding-left: 32px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .contactt {
        padding-left: 32px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }
}

@media(min-width:1600px) {

    .homeee {
        padding-right: 35px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .collectionnn {
        padding-right: 35px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .articlee {
        padding-left: 35px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .blogg {
        padding-left: 35px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .watchh {
        padding-left: 35px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .contactt {
        padding-left: 35px;
    }
}

@media(min-width:1800px) {

    .homeee {
        padding-right: 45px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .collectionnn {
        padding-right: 45px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .articlee {
        padding-left: 45px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .blogg {
        padding-left: 45px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .watchh {
        padding-left: 45px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .contactt {
        padding-left: 45px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }
}

@media(min-width:1900px) {

    .homeee {
        padding-right: 50px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .collectionnn {
        padding-right: 50px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .articlee {
        padding-left: 50px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .blogg {
        padding-left: 50px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .watchh {
        padding-left: 50px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .contactt {
        padding-left: 50px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }
}

.cont_fluid {
    padding: 0px;
}

/* @media(max-width:991px){
    #rolex_submenu_active{
        border-bottom: 1px solid #18191F !important;
        font-weight: 600;
    }
    #vintage_submenu_active{
        border-bottom: 1px solid #18191F !important;
        font-weight: 600;
    }
    #archive_submenu_active{
        border-bottom: 1px solid #18191F !important;
        font-weight: 600;
    }
} */

@media(max-width:991px) {
    #rolex_submenu_active.smallactive {
        border-bottom: 1px solid #18191F !important;
        font-weight: 600;
    }

    #vintage_submenu_active.smallactive {
        border-bottom: 1px solid #18191F !important;
        font-weight: 600;
    }

    #archive_submenu_active.smallactive {
        border-bottom: 1px solid #18191F !important;
        font-weight: 600;
    }

    .nav-link.active2 {
        border-bottom: 1px solid transparent !important;
    }
}

@media(min-width:576px) {
    img#white_logo {
        width: 300px !important;
    }
}

@media(min-width:1600px) {
    img#white_logo {
        width: 360px !important;
    }
}