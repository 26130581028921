@media(max-width: 991px){
    
    .introduction_image{

        width: 100%;
    }
}
.introductionn > p{
    font-weight: 200 !important;
}
.homepage4{
    background-color: #F9F9F9;
}