.hello_hello1{ 
    /* display: none; */
    position: fixed;
    width: 40px;
    height: 40px; 
    right: 2%;
    bottom: 5%;
    border-radius: 50%;
    margin: 0px;
    font-size: 3rem;
    z-index: 1;
    cursor: pointer;
    color: #212529;
    background-color: white;
    opacity: 0.8;
}
.arrow{

    margin-top: -10px;
    margin-bottom: 35px;
    margin-left: -5px;
}
.hello_hello2{ 
    position: fixed;
    width: 40px;
    height: 40px; 
    right: 2%;
    bottom: 5%;
    border-radius: 50%;
    margin: 0px;
    font-size: 3rem;
    z-index: 1;
    cursor: pointer;
    color: #212529;
    background-color: transparent;
}
@media (max-width:480px)  {

    .hello_hello1,.hello_hello2{ 
        
        width: 26px;
        height: 26px; 
        right: 3%;
        bottom: 2.5%;
        border-radius: 50%;
        margin: 0px;
        font-size: 2rem;
    }
    .arrow{
        
        margin-top: -5px;
        margin-bottom: 30px;
        margin-left: -3px;
    }
}
@media (min-width: 481px) and (max-width: 575px) {

	.hello_hello1,.hello_hello2{ 
        
        width: 26px;
        height: 26px; 
        right: 3%;
        bottom: 2.5%;
        border-radius: 50%;
        margin: 0px;
        font-size: 2rem;
    }
    .arrow{
        
        margin-top: -5px;
        margin-bottom: 30px;
        margin-left: -3px;
    }
}
@media (min-width: 576px) and (max-width: 767px) {

	.hello_hello1,.hello_hello2{ 
        
        width: 26px;
        height: 26px; 
        right: 3%;
        bottom: 2.5%;
        border-radius: 50%;
        margin: 0px;
        font-size: 2rem;
    }
    .arrow{
        
        margin-top: -5px;
        margin-bottom: 30px;
        margin-left: -3px;
    }
}
 
@media (min-width: 768px) and (max-width:991px){

    .hello_hello1,.hello_hello2{ 
        
        width: 26px;
        height: 26px; 
        right: 2%;
        bottom: 3%;
        border-radius: 50%;
        margin: 0px;
        font-size: 2rem;
    }
    .arrow{
        
        margin-top: -5px;
        margin-bottom: 30px;
        margin-left: -3px;
    }
}

@media (min-width:992px) and (max-width:1281px){

    .hello_hello1,.hello_hello2{ 
        
        width: 28px;
        height: 28px; 
        right: 2%;
        bottom: 3%;
        border-radius: 50%;
        margin: 0px;
        font-size: 2.3rem;
    }
    .arrow{
        
        margin-top: -10px;
        margin-bottom: 30px;
        margin-left: -5px;
    }
}

@media (min-width:1282px) and (max-width:1481px){

    .hello_hello1,.hello_hello2{ 
        
        width: 30px;
        height: 30px; 
        right: 1.5%;
        bottom: 3%;
        border-radius: 50%;
        margin: 0px;
        font-size: 2.4rem;
    }
    .arrow{
        
        margin-top: -10px;
        margin-bottom: 30px;
        margin-left: -5px;
    }
}

@media (min-width:1482px) and (max-width:1680px){

    .hello_hello1,.hello_hello2{ 
        
        width: 37px;
        height: 37px; 
        right: 2%;
        bottom: 5%;
        border-radius: 50%;
        margin: 0px;
        font-size: 2.8rem;
    }
    .arrow{
        
        margin-top: -10px;
        margin-bottom: 30px;
        margin-left: -5px;
    }
}

@media (min-width:1681px) and (max-width:1799px){

    .hello_hello1,.hello_hello2{ 
        
        width: 37px;
        height: 37px; 
        right: 2%;
        bottom: 5%;
        border-radius: 50%;
        margin: 0px;
        font-size: 2.8rem;
    }
    .arrow{
        
        margin-top: -10px;
        margin-bottom: 30px;
        margin-left: -5px;
    }
}