.form{
            
    box-shadow: none !important;
}
.textOnInput{

    width:100%;
    margin-bottom: 10%;
}
@media(min-width:576px){
    
    .textOnInput{

        margin-bottom: 8%;
    }
}
@media(min-width: 768px){
    
    .textOnInput{

        margin-bottom: 6%;
    }
}
@media(min-width: 992px){
    
    .textOnInput{

        margin-bottom: 10%;
    }
}
@media(min-width: 1200px){
    
    .textOnInput{

        margin-bottom: 8%;
    }
}
.textOnInput2{

    width: 100% !important;
}
.textOnInput {

    position: relative;
}
.textOnInput label {
    
    position: absolute;
    top: -10px;
    left: 9px;
    padding: 2px;
    z-index: 1;
    background: #fff;
    color:#696F79;
    padding: 0px 5px;
    font-size: 14px;
}
.textOnInput:focus label{
    color: #000;
}
.form-control {

    padding: 0.675rem 0.85rem;
    border: 1px solid #8692A6;
}
@media(min-width:1600px){
    
    .form-control {
        padding: 0.875rem 0.75rem;
    }
}
@media(min-width:1800px){
    
    .form-control {

        padding: 1rem 0.75rem;
    }
    .textOnInput label {
    
        top: -13px;
    }
}
.textOnInput input,
.textOnInput textarea{
    font-weight: 500;
}
.textOnInput input:focus,
.textOnInput2 textarea:focus{

    border: 1px solid #000;
    box-shadow: 0 0 0 50px white inset;
}
.textOnInput input:focus ~ label,
.textOnInput2 textarea:focus ~ label{

    color:#000;
}

/***** Submit button *******/
.submit_button{

    padding: 13px 25px;
}

/************* For dynamic content css ****************************/
#dangerous > p{
    margin-bottom: 0px;
    padding-bottom: 10px;
    font-weight:400;
}
#dangerous > p img{
    
    display: flex;
    margin: 0 auto;
    max-width: 100%;
    margin-bottom: 25px;
    margin-top: 25px;
} 
#dangerous > p a{
    color:#000;
}
.contact_images p{
text-align: center;
}