.wholepopup{

    background: #ffffff;
    min-height:90vh;
    padding: 5% 0% 0%;
    height: auto;
}
@media(min-width:768px){
    
    .wholepopup{

        min-height:75vh;
        padding: 5% 0%;
        height: auto;
    }
}
.popup{

    width: 100%;
    background-color: white;
}
.popup_header{

    max-width: 100%;
}
.close{

    width:25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 15px;
    top: 7px;
    right: 7px;
    background-color: white;
    border-radius: 50%;
}
.icon{
    width: 35px;
    margin-right: 15px;
}
.for_padding_of_content{
    padding: 5%;
}
.popup-content{
    padding-top: 10%;
}
.next_image{
    
    width: 7px;
}
@media(min-width:576px){

    .popup{

        width: 60%;
    }
}
@media(min-width:768px){

    .popup{

        width: 90%;
    }
    .icon{
        width: 20px;
    }
    .for_padding_of_content{
        padding: 3%;
    }
}
@media(min-width:992px){
    
    .popup-content{
        padding-top: 10%;
    }
    .popup{

        width: 70%;
    }
    .icon{
        width: 25px;
    }
}
@media(min-width:1200px){
    .icon{
        width: 30px;
    }
}
@media(min-width:1300px){
    .icon{
        width: 32px;
    }
}
@media(min-width:1500px){

    .popup_header{

        width: 400px;
    }
    .close{

        width: 28px;
        height: 28px;
        font-size: 17px;
    }
}
@media(min-width:1600px){

    .popup_header{

        width: 450px;
    }
    .close{

        width:32px;
        height: 32px;
        font-size: 18px;
    }
}
@media(min-width:1800px){

    .popup{

        width: 65%;
    }
    .popup_header{

        width: 500px;
    }
    .close{

        width:35px;
        height: 35px;
        font-size: 20px;
    }
}
.enter_content{
    bottom: 6%;
}
@media(min-width:768px){
    .enter_content{
        bottom: 5%;
    }   
}
@media(min-width:992px){
    .enter_content{
        bottom: 0%;
    }   
}
.rightside_content{
    height: 280px;
}
@media(min-width:768px){
    
    .rightside_content{
        height: 100%;
        min-height: 290px;
    }
}
@media(min-width:1500px){
    
    .rightside_content{
        
        min-height: 370px;
    }
}


/***** Social Icons *******/
.icon3,.email{
    width: 30px;
}
@media(min-width:992px){
    .icon3,.email{
        width: 35px;
    }   
}
@media(min-width: 1400px){
    .icon3,.email{
        width: 40px;
    }   
}
@media(min-width:1600px){
    .icon3,.email{
        width: 45px;
    }   
}
.icon3:hover {
    background: #262626;
    border-radius: 50%;

}
.icon3:hover path{

    fill: #fff;
}
.email:hover{
    background: #262626;
    border-radius: 50%;
}
.email:hover path{
    stroke: #fff;
}
.social-text_content,
.social-text_content2{
    color: #000;
}
.social-text_content:hover,
.social-text_content2:hover{

    color:#262626;
}
.social-text_content2:hover svg path{
    stroke: #262626;
}

@media(min-width:992px){

    .back_image_color{
        background:#f0f0f0;
    }    
}
@media(min-width:768px){

    .for_height{
        min-height: 290px;
    }
}
@media(min-width:1500px){

    .for_height{
        min-height: 370px;
    }
}
@media(max-width:767px){
    .navbartopspace{
        padding-top:0px !important;
    }
}
.logo2_bottom{
    padding-bottom: 30px;
    max-width: 90%;
}
.enter_arrow_image{
    width:10px;
    height:10px;
}
@media(min-width:768px){
    
    .enter_arrow_image{

        height:12px;
    }
}
@media(min-width:1200px){
    
    .enter_arrow_image{

        height:15px;
    }
}