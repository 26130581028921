.carousel.slide.carousel-fade {
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
.carousel-inner {
  width: 85%;
  /* width: max-content; */
}
@media (min-width: 576px) {
  .carousel-inner {
    width: 55%;
    /* width: max-content; */
  }
}
@media (min-width: 992px) {
  .carousel-inner {
    width: 45%;
  }
}
@media (min-width: 1200px) {
  .carousel-inner {
    width: 48%;
    /* width: max-content; */
  }
}
