/********** Common Css *********/
.collection .heading_text{

    transform: translate(-50%, -50%);
    padding: 0% 5%;
}
@media(min-width:992px){
    
    .collection .heading_text{

        padding: 0% 0%;
    }
}
/******** Vintage Css************/
.vinatage .bannerimage{

    min-height: 30vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
@media(max-width:576px){
    .vinatage .bannerimage{

        min-height: 35vh;
    }
}
/******** Rolex Css************/
.rolex .bannerimage{

    min-height: 30vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
@media(max-width:576px){
    .rolex .bannerimage{

        min-height: 35vh;
    }
}
/******** Archive Css************/
.archive .bannerimage{

    min-height: 30vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
@media(max-width:576px){
    .archive .bannerimage{

        min-height: 35vh;
    }
}
/***** Sold Out ********/
.soldout{

    background: #BFBFBF;
    color:#fff;
    border: none !important;
    box-shadow: none !important;
    /* cursor:no-drop !important; */
}
.soldout:hover{
    color: #fff;
}

.watches{
    min-height: auto;
    max-height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}
.watches img{
    max-height: auto;
}


/*************** Carousel Css **************************/
.homepage4_thumb_carousel{
    padding: 0% 0%;
}
.carousel .thumbs-wrapper{

    margin:0px;
    display: flex;
    justify-content: center;
}
.carousel .thumb.selected, .carousel .thumb:hover{
    border: none;
}
.carousel .thumbs{

    display: flex;
    justify-content: center;
    padding: 0px;
    margin: 0px;
    width:100%;
    /* transform: none !important; */
}
.carousel .thumb,
.carousel .thumb:focus
{ 
    border:none;
    padding: 5px;
    margin: 0px;
    /* width: 25% !important; */
}
.carousel .control-arrow:before{
    margin: 0px !important;
}
.carousel .control-prev.control-arrow{
    left:-20px;
}
.carousel .control-next.control-arrow{  
    right: -20px;
} 
.carousel .control-prev.control-arrow:before {
    border-right: 8px solid #000;
}
.carousel .control-next.control-arrow:before {
    border-left: 8px solid black;
}
@media(min-width: 576px){
    
    .homepage4_thumb_carousel{
        padding: 0% 8%;
    }   
}
@media(min-width: 768px){
    
    .homepage4_thumb_carousel{
        padding: 0% 10%;
    }   
    .carousel .control-prev.control-arrow{
        left:-22px;
    }
    .carousel .control-next.control-arrow{  
        right: -22px;
    } 
    .carousel .control-prev.control-arrow:before {
        border-right: 11px solid #000;
    }
    .carousel .control-next.control-arrow:before {
        border-left: 11px solid black;
    }
}
@media(max-width:575px){
    
    .thumb{

        /* width: 500px !important; */
    }
    .thumb img{

        width: 100%;
    }
}
.thumbs{
    display:flex;
    align-items: center;
}

.parent_class{

    z-index: 10;
}
.bg-gradient{
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.9);
}
.slider_image_width{
    width:80%;
}
.slider_close_icon{
    fill: white;
    width: 30px;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 9;
}
@media(min-width:576px){
    
    .slider_close_icon{

        width: 50px;
        top: 40px;
        right: 40px;
    }
    .slider_image_width{
        width:60%;
    }
}
@media(min-width:768px){
    
    .slider_close_icon{

        width: 50px;
        top: 40px;
        right: 40px;
    }
    .slider_image_width{
        width:60%;
    }
}
@media(min-width:992px){
    .slider_close_icon{
        fill: white;
        width: 35px;
        top: 20px;
        right: 20%;
        cursor: pointer;
    }
    .slider_image_width{
        width:40%;
    }
}