.homepage5 .image_content{

    position: relative;
    background: #000;
    opacity: 1;
    backdrop-filter: blur(4px);
    margin-top: -2px;
}
.homepage5 .image_content .heading{

    min-height:50px;
}
@media(min-width:768px){
     
    .homepage5 .image_content{

        position: absolute;
        border-radius: 0px 55px 0px 0px ;
    }
}